import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import Moment from "react-moment";
import 'moment/locale/es-mx';
import 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';



const PostField = ({post, putPost, deletePost}) => {

    const [ modalDetail, setModalDetail ] = useState(false);
    const [ modalEdit, setModalEdit ] = useState(false);
    const [ modalDelete, setModalDelete ] = useState(false);
    const [ selectedPost, setSelectedPost ] = useState({});

    const detailClick = (post) =>{
        setSelectedPost(post);
        setModalDetail(true);
    }

    const editClick = (post) =>{
        setModalDetail(false);
        setModalEdit(true);
    }

    const validations = yup.object({
        title: yup.string()
        .min(5, 'Debe tener como mínimo 5 caracteres')
        .max(120, 'Puedes ingresar hasta 120 caracteres')
        .required('Debes ingresar un título para la publicación'),
        body: yup.string()
        .min(30, 'La publicación es muy corta')
        .max(1500, 'Puedes ingresar hasta 1500 caracteres')
        .required('El cuerpo de la publicación es obligatorio'),
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue  } = useForm({
        resolver: yupResolver(validations),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });


    const onSubmit = async (data) =>{
        let dat = {
            id: selectedPost.id,
            title: data.title,
            body: data.body
        }

        putPost(dat);
        reset();
        setModalEdit(false)
    }

    return (
        <>
            <div className="post-field">
                <span className="title-post">{post.title}</span>
                <Moment 
                    fromNow 
                    locale="ar"
                    tz="America/Argentina/Buenos_Aires"
                >{post.date}</Moment>
                <label className="btn btn-primary" onClick={()=> detailClick(post)}>Ver detalle</label>
            </div>

            <Modal isOpen={modalEdit}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        Editar: {selectedPost.title}
                    </ModalHeader>
                    <ModalBody>
                        <div class="form-group">
                            <label htmlFor="title">Titulo</label>
                            <input type="text" name="title" id="title"  className="form-control"  {...setValue('title',selectedPost.title)} {...register('title')}/>
                            {errors.title && <span>{errors.title?.message}</span>} 
                        </div>
                        <div class="form-group my-3" >
                            <label htmlFor="body">Cuerpo</label>
                            <textarea type="text" name="body" id="body"  className="form-control" rows="5" {...setValue('body',selectedPost.body)} {...register('body')}/>
                            {errors.body && <span>{errors.body?.message}</span>} 
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="w-100 d-flex justify-content-center">
                            <button 
                                className="btn btn-warning mx-3"
                                
                                onClick={()=>{
                                    editClick(selectedPost)
                                    setModalEdit(false)
                                    setSelectedPost({})
                                }}
                            >Cancelar</button>
                            <button 
                                className="btn btn-success mx-3"
                                type="submit"
                            >Guardar</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            
            <Modal isOpen={modalDetail}>
                <div className="modal-header p-3 d-flex w-100 justify-content-between">
                    <div className="d-flex flex-column">
                        <p className="modal-title">{selectedPost.title}</p>
                        <Moment 
                                fromNow 
                                locale="ar"
                                tz="America/Argentina/Buenos_Aires"
                                className="moment-modal"
                        >{post.date}</Moment>
                    </div>
                    <p className="close-btn" onClick={()=>{setSelectedPost({}); setModalDetail(!modalDetail);}}>X</p>
                </div>
                <ModalBody>
                    <div className="w-100 h-100">
                        <p>{selectedPost.body}</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-center">
                        <button 
                            className="btn btn-warning mx-3"
                            onClick={()=>editClick(selectedPost)}
                        >Editar</button>
                        <button 
                            className="btn btn-danger mx-3"
                            onClick={()=>{
                                setModalDetail(false)
                                setModalDelete(true)
                            }}
                        >Eliminar</button>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDelete}>
                <ModalHeader>
                    ¿Eliminar: {selectedPost.title}?
                </ModalHeader>
                <ModalBody>
                    <h6 className="text-center my-5">
                        Esta acción no puede deshacerse
                    </h6>
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-center">
                        <button 
                            className="btn btn-warning mx-3"
                            onClick={()=>{
                                setModalDelete(false)
                                setSelectedPost({})
                            }}
                        >Cancelar</button>
                        <button 
                            className="btn btn-danger mx-3"
                            onClick={()=>{
                                deletePost(selectedPost)
                                setSelectedPost({});
                                setModalDelete(false);
                            }}
                        >Eliminar</button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default PostField
