import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import axios from 'axios';
const { REACT_APP_BACKEND_URL } = process.env;

const Media = () => {
    const [ videos, setVideos ] = useState([]);

    useEffect(() => {
        getVideos();
    }, [])

    const getVideos = async () =>{
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}video/`);
        setVideos(data);
        console.log(data);
    }

    return (
        (videos.length>0) && <section className="media" id="media">

            <div className="title" data-aos="fade-down">
                <h3>Mediateca</h3>
                <span className="subs"></span>
            </div>
            <div className="splide-container">
                <Splide options={{arrows:true, pagination: false, drag: false, rewind: true, arrows:true, perPage: 1 }}>
                    {videos.map(video =>
                            <SplideSlide key={video.id}>
                                <div className="slide-container">
                                <LiteYouTubeEmbed 
                                    id={video.link}
                                    title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                                />
                            </div>
                            </SplideSlide>    
                    )}
                </Splide>
            </div>
        </section>
    )
}

export default Media
