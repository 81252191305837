import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import { setToken, removeToken, getToken, initAxiosInterceptors } from "./helpers/authHelper";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './views/Home';
import Login from './views/Login';
import Articles from './views/Articles';
import Panel from './views/Panel';

const { REACT_APP_BACKEND_URL } = process.env;

initAxiosInterceptors();

function App() {  

  const [ user, setUser ] = useState(null);
  const [ loadingUser, setLoadingUser ] = useState(true);

  useEffect(()=>{
    const loadUser = async () =>{
      if (!getToken()){
        setLoadingUser(false);
        return;
      }
      
      try {
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}whoami`);
        setUser(data.user);
        setLoadingUser(false);
      } catch (error) {
        console.log(error);
        window.location="/";
      }
    } 

    loadUser();
  },[])

  const login = async (user) =>{
    const { data } = await axios.post(`${REACT_APP_BACKEND_URL}login`,user); 
    setUser(data.user);
    setToken(data.token);
    window.location="/panel";
  }

  const logout = async () =>{
    setUser(null);
    removeToken();
    window.location="/login";
  }

  return (
    <div className="">
      
      <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" render={() => <Login login={login}/>} />
            <Route exact path="/articles" component={Articles}/>
            {user && <Route exact path="/panel"  render={() => <Panel logout={logout}/>}/>}
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;