import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageField from './components/ImageField';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const { REACT_APP_BACKEND_URL } = process.env;

const Images = () => {

    const [ images, setImages ] = useState([]);
    const [ modal, setModal ] = useState(false);
    const [ file, setFile ] = useState();
    

    useEffect(() => {
        getImages();  
    }, [])

    
    
    const getImages = async () =>{
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}image/`);
        setImages(data);
    }

    const deleteImage = async (id) =>{
        await axios.delete(`${REACT_APP_BACKEND_URL}image/${id}`);
        getImages();
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        console.log(file);
        const data = new FormData();
        data.append("file", file); 
        await axios.post(`${REACT_APP_BACKEND_URL}image/`,data);
        setModal(!modal);
        getImages();
    }

    return (
        <>
        <div className="menu">
            <div className="body">
            {
                images.length > 0 ?
                images.map(image => <ImageField image={image} deleteImage={deleteImage} getImages={getImages}/>)
                :
                <div className="empty-container">
                    <label className="empty">No hay eventos aún, ¡sube uno!</label>
                </div>
            }
            </div>
            <div className="footer">
                <span className="btn btn-warning mt-4" onClick={()=>setModal(!modal)}>Registrar evento</span>
            </div>
        </div>


        <Modal isOpen={modal}>
            <form onSubmit={handleSubmit}>
                <ModalHeader>Agrega un evento</ModalHeader>
                <ModalBody>
                    <div className="form-group w-100 my-5">
                        <div className="d-flex justify-content-center">
                            <input 
                                className="d-flex flex-column" 
                                type="file" 
                                id="file"
                                name="file"  
                                onChange={
                                    e => setFile(e.target.files[0])
                                }
                            />
                        </div>
                    </div>
                </ModalBody>    
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-center">
                        <button 
                            class="btn btn-secondary mx-3" 
                            onClick={()=>setModal(!modal)}>Cancelar</button>

                        <button 
                            class="btn btn-success mx-3" >Confirmar
                        </button>
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    </>
    )
}

export default Images

