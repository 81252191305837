import React, { useState, useEffect } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

const Webinar = () => {

    const [ modal, setModal ] = useState(false);
    const [ images, setImages ] = useState([]);

    useEffect(() => {
        getImages();
        
    }, [])

    const getImages = async () =>{
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}image/`);
        setImages(data);
        console.log(data);
    }

    return (
        <>
            {images.length>0 && <section class="webinar" id="webinar">

                <div className="title" data-aos="fade-down">
                    <h3>Próximos Eventos</h3>
                    <span className="subs"></span>
                </div>
                <div className="splide-container">
                    <Splide options={ { rewind: true, pagination: false } }>
                        {images.map(image=>
                            <SplideSlide key={image.id}>
                                <div className="img-container">
                                    <img src={`${REACT_APP_BACKEND_URL}files/${image.name}`} alt="Image 1"/>
                                </div>
                            </SplideSlide>
                        )}
                    </Splide>

                </div>
           
            </section>}

            <Modal isOpen={modal}>
                <ModalBody>
                    <div className="_modal-container">
                        <div className="w-100">
                            <img  className="w-100" src="./test-presentacion.png" alt="" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button color="primary" className="button mx-3" onClick={()=>setModal(!modal)}>Link evento</Button>
                            <Button color="secondary" className="button mx-3" onClick={()=>setModal(!modal)}>Cerrar</Button>
                        </div>
                    </div>
                </ModalBody>    
            </Modal>
        </>
    )
}

export default Webinar
