import React, { useState } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const VideoField = ({video, deleteVideo, getVideos}) => {

    const [ modal, setModal ] = useState(false);
    const [ selectedVideo, setSelectedVideo ] = useState({});

    return (
        <>
            <div className="field p-3">
                <LiteYouTubeEmbed 
                    id={video.link}
                    title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                />

                <div className="d-flex justify-content-center mt-3">
                    <button className="btn btn-danger mx-3" onClick={()=>{setModal(!modal); setSelectedVideo(video);}}>BORRAR</button>
                </div>
            </div>

            <Modal isOpen={modal}>
                <ModalHeader>¿Deseas eliminar este video?</ModalHeader>
                <ModalBody>
                    <LiteYouTubeEmbed 
                        id={selectedVideo.link}
                        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                    />
                    <div className="d-flex w-100 my-2 justify-content-center">
                        <span>Esta acción no podrá deshacerse</span>
                    </div>
                    
                    
                </ModalBody>   
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-center">
                        <button 
                            class="btn btn-secondary mx-3" 
                            onClick={()=>{
                                setModal(!modal); 
                                setSelectedVideo({});
                            }}>Cancelar</button>

                        <button 
                            class="btn btn-warning mx-3" 
                            onClick={()=>{
                                setModal(!modal); 
                                deleteVideo(selectedVideo.id);
                                setSelectedVideo({});
                                getVideos();
                                
                        }}>Confirmar</button>
                    </div>
                </ModalFooter> 
            </Modal>
        </>
    )
}

export default VideoField
