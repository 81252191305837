import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { REACT_APP_BACKEND_URL } = process.env;
const MySwal = withReactContent(Swal);


const Contact = () => {

    const validations = yup.object({
        name: yup.string()
        .min(5, 'Debe tener como mínimo 5 caracteres')
        .max(30, 'Puedes ingresar hasta 30 caracteres')
        .required('Debes ingresar tu nombre'),
        email: yup.string()
        .email('El email debe ser válido')
        .required('El email es obligatorio sino no puedo responderte'),
        subject: yup.string()
        .min(5, 'Debe tener como mínimo 5 caracteres')
        .max(60, 'Puedes ingresar hasta 1500 caracteres')
        .required('El asunto es obligatorio'),
        message: yup.string()
        .min(20, 'El mensaje es muy corto')
        .max(150, 'Puedes ingresar hasta 1500 caracteres')
        .required('El mensaje es obligatorio'),
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue  } = useForm({
        resolver: yupResolver(validations),
    });

    const onSubmit = async (data) =>{
        console.log(data);
        try {
            await axios.post(`${REACT_APP_BACKEND_URL}message`,{
                name: data.name,
                email: data.email,
                subject: data.subject,
                message: data.message
            });
            // ENVIAR MESSAGE         
            Swal.fire('Mensaje enviado, te contestaré a la brevedad', '', 'success') 
            reset();
            
        } catch (error) {
          console.log(error);
        }
    }

    return (
        <form className="form-contact" onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
                <input type="text" className="name" placeholder="Nombre" id="name" {...register('name')}/>
                {errors.body && <span>{errors.body?.name}</span>}
            </div>

            <div className="field">
                <input type="email" className="email" placeholder="Correo" id="email" {...register('email')}/>
                {errors.body && <span>{errors.body?.email}</span>}
            </div>                    
            
            <div className="field">
                <input type="text" className="subject" placeholder="Asunto" id="subject" {...register('subject')}/>   
                {errors.body && <span>{errors.body?.subject}</span>}
            </div>
            
            <div className="field message">
                <textarea type="text" className="message" placeholder="Mensaje" id="message" {...register('message')}/> 
                {errors.body && <span>{errors.body?.message}</span>}
            </div>
            <button className="btn btn-warning" type="submit">Enviar</button>
        </form>
    )
}

export default Contact
