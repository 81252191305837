import React, { useState, useEffect } from 'react'
import Footer from '../commons/Footer';
import Navbar from '../commons/Navbar';
import axios from 'axios';
import Aos from "aos";
import "aos/dist/aos.css";
import Moment from "react-moment";
import 'moment/locale/es-mx';
import 'moment-timezone';


const { REACT_APP_BACKEND_URL } = process.env;

const Article = () => {

    const [ posts, setPosts ] = useState([]);

    useEffect(() => {
        const getPosts = async () =>{
            const { data } = await axios.get(`${REACT_APP_BACKEND_URL}post`);
            setPosts(data);
        }

        Aos.init({duration:2000})
        getPosts();
    }, [])


    return (
        <>
            <Navbar/>
            <section className="article">
                <div className="title mt-5" data-aos="fade-down">
                    <h3>Escritos</h3>
                    <span className="subs"></span>
                </div>
                <div className="container">

                { posts.map (post =>
                    <div className="writes" data-aos="fade-up" >
                        <div className="box">
                            <div className="title">{post.title}</div>
                            <div className="date">
                            <Moment 
                                fromNow 
                                locale="ar"
                                tz="America/Argentina/Buenos_Aires"
                            >
                                {post.date}
                            </Moment>
                            </div>
                            <div className="text">{post.body}</div>
                            
                            <div className="share">
                                <i className="fab fa-facebook-f"></i>
                                <i className="fab fa-twitter"></i>
                                <i className="fab fa-instagram"></i>
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                        </div>
                    </div>
                ) }
                    
                </div>
                
            </section>
            <Footer/>
        </>
    )
}

export default Article
