import axios from 'axios';

const TOKEN_KEY = 'token';

export function setToken (token){
    localStorage.setItem(TOKEN_KEY,token);
}

export function getToken () {
    return localStorage.getItem(TOKEN_KEY);
}

export function removeToken () {
    localStorage.removeItem(TOKEN_KEY);
}



export function initAxiosInterceptors () {

    axios.interceptors.request.use(function(config){

        const token = getToken();
        
        if (token){
            config.headers.Autorization = token; 
        }
        return config;
    });

    axios.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error.response.status === 401) {
            removeToken();
            window.location = '/login';
          } else {
            return Promise.reject(error);
          }
        }
      );
}