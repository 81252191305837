import React, { useState, useEffect } from 'react';
import Posts from './Posts';
import Videos from './Videos';
import Navbar from './commons/Navbar';
import Newsletter from '../commons/Newsletter';
import Images from './Images';
import cors from 'cors';

const Panel = ({ logout }) => {

    const [ menu, setMenu ] = useState(0);

    return (
        <section className="panel">
            <Navbar logout={logout}/>
            <div className="side">
                <ul>
                    <li><a onClick={()=>setMenu(0)}>Publicaciones</a></li>
                    <li><a onClick={()=>setMenu(1)}>Mediateca</a></li>
                    <li><a onClick={()=>setMenu(2)}>Eventos</a></li>
                </ul>
            </div>
            <div className="content">
                { menu==0 && <Posts/> }
                { menu==1 && <Videos/> }
                { menu==2 && <Images/> }
            </div>
        </section>
    )
}

export default Panel
