import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Link as LinkJ} from 'react-scroll';

const Navbar = () =>{

    const [ toggleMenu, setToggleMenu ] = useState(false);

    let hiddeNav = () =>{
        setToggleMenu(!toggleMenu);
    }

    return <nav className={toggleMenu ? 'active':''}>
            <ul>
                <li><LinkJ className="link" to="aboutme" smooth={true}>Sobre mi</LinkJ></li>
                <li><LinkJ className="link"  to="book" smooth={true}>Libro</LinkJ></li>
                <li><LinkJ className="link" to="talent" smooth={true}>Consultora</LinkJ></li>
                <li><LinkJ className="link"  to="media" smooth={true}>Mediateca</LinkJ></li>
                <li><LinkJ className="link"  to="articles" smooth={true}>Escritos</LinkJ></li>
                <li><LinkJ className="link"  to="eventos" smooth={true}>Eventos</LinkJ></li>
            </ul>
            <button onClick={hiddeNav} className="btn">{toggleMenu ? <i className="fal fa-arrow-alt-from-right"></i> : <i className="fal fa-arrow-alt-from-left"></i>}</button>
        </nav>
}

export default Navbar;
