import React from 'react';
import { Link } from 'react-router-dom';

const Login = ({login}) => {
 
    const handleSubmit = async (e) =>{
        e.preventDefault();
        if (e.target.email.value && e.target.password.value){
            login({
                email: e.target.email.value,
                password: e.target.password.value,
            })
        }
    }

    return (
        <section className="login-section">
            <Link to="/" className="back-link"><i className="fal fa-chevron-circle-left"></i>Volver al sitio</Link>
            <form className="box" onSubmit={handleSubmit}>
                <div className="container">
                    <label className="title">Iniciar sesión</label>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input type="text" id="email" name="email"/>
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password"/>
                    </div>
                    <button className="btn btn-warning">Iniciar sesion</button>
                </div>
                <img src="/login.png" alt="" />
            </form>
        </section>
    )
}

export default Login
