import React, { useEffect, useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Link as LinkJ, animateScroll as scroll } from 'react-scroll';
import Moment from "react-moment";
import 'moment/locale/es-mx';
import 'moment-timezone';


const { REACT_APP_BACKEND_URL } = process.env;

const Articles = () => {

    const [ lastPosts, setLastPosts ] = useState([]);

    useEffect(() => {
        Aos.init({duration:1000})

        const getPosts = async () =>{

            const { data } = await axios.get(`${REACT_APP_BACKEND_URL}post/`);

            setLastPosts(data.slice(0,3));
        }

        getPosts();

    }, [])


    const reducerTextBody = (text) =>{
        
        let res = "";

        text.length > 190 ? 
            res = text.slice(0, 180)+'...'
        : 
            res = text ;

        return res;
    }

    return (
        (lastPosts.length>0) && <section className="articles" id="articles">
            <div className="title" data-aos="fade-down">
                <h3>Escritos</h3>
                <span className="subs"></span>
            </div>
            <div className="container">

                {lastPosts.map((post,i)=>
                    
                    <div className="cardPost" data-aos="fade-down" data-aos-delay={(i+1)*100}>
                        <div className="header"><i class="fal fa-clock"></i> 
                            <Moment 
                                fromNow 
                                locale="ar"
                                tz="America/Argentina/Buenos_Aires"
                            >{post.date}</Moment>
                        </div>
                        <div className="text">
                            <h5>{post.title}</h5>
                            <h6>{reducerTextBody(post.body)}</h6>
                        </div>
                    </div>
                    
                )}

            </div>
            {lastPosts.length>0 && <div className="more" data-aos="fade-up"><Link to="/articles" onClick={()=>scroll.scrollToTop()}>Ver las entradas anteriores</Link></div>}
        </section>
    )
}

export default Articles
