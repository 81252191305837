import React, { useState } from 'react';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const { REACT_APP_BACKEND_URL } = process.env;

const ImageField = ({image, deleteImage, getImages}) => {

    const [ modal, setModal ] = useState(false);


    return (
        <>
            <div className="field p-3">
                <img  src={`${REACT_APP_BACKEND_URL}files/${image.name}`}   className="w-100" alt="" />

                <div className="d-flex justify-content-center mt-3">
                    <button className="btn btn-danger mx-3" onClick={()=>{setModal(!modal)}}>BORRAR</button>
                </div>
            </div>

            <Modal isOpen={modal}>
                <ModalHeader>
                    ¿Deseas eliminar esta imagen?
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex w-100 my-5 justify-content-center">
                        <span>Esta acción no podrá deshacerse</span>
                    </div>
                </ModalBody>  
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-center">
                        <button 
                            class="btn btn-secondary  mx-3" 
                            onClick={()=>{
                                setModal(!modal); 
                            }}>Cancelar</button>

                        <button 
                            class="btn btn-danger mx-3" 
                            onClick={()=>{
                                setModal(!modal); 
                                deleteImage(image.id);
                                getImages();
                            }}
                            >Confirmar</button>
                    </div>
                </ModalFooter>  
           </Modal>
        </>
    )
}

export default ImageField
