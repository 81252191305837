import React, { useEffect, useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";


const Aboutme = () => {


    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    const [ count, setCount ] = useState(0);

    const backButton = () =>{
        if (count>0)
        setCount(count-1);
    }

    const nextButton = () =>{
        if (count<4)
        setCount(count+1);
    }

    return (
    
        <section className="aboutme" id="aboutme">
            <div className="side-about" data-aos="fade-right">
                <p className="name">Diego Quindimil</p>
            </div>
            <div className="content" data-aos="fade-up" data-delay="900">
                <div className="title" data-aos="fade-down">
                    <h3>Biografía</h3>
                    <span className="subs"></span>
                </div>
                <div className="paragraph">
                    <p>Psicólogo (UBA). Trabajo día a día en el desarrollo del potencial, la resolución de los conflictos y la salud psíquica, con personas, equipos y organizaciones. Soy docente de la Universidad de Buenos Aires (UBA), dirijo el programa Liderazgo y Gestión de Equipos de la Universidad Torcuato Di Tella (UTDT) y soy profesor titular de la cátedra Psicología Organizacional y Empresaria 1 y 2 de la Universidad Maimónides (UMAI). Soy director del Posgrado: "Gestión Humana en las Organizaciones. Prácticas y Procesos" en la UBA. Me desempeño diariamente como coach ejecutivo, psicólogo clínico y consultor en Recursos Humanos. Dirijo la consultora Contenido Humano. Estoy finalizando la Maestría en Dirección y Gestión de RRHH en la Universidad Blas Pascal (UBP).  He trabajado con organizaciones de Argentina, Bolivia, Brasil, Chile, Ecuador, España, Estados Unidos, Israel, México, Perú, República Dominicana, Singapur, Suiza y Uruguay.</p>
                    <p>Me convocan como conferencista, speaker y tengo participación activa en TV, Radio y diferentes medios gráficos. Soy autor del libro “Mundo post covid. La Psicología del trabajo tras la pandemia” publicado por Editorial Granica</p>
                </div>
            </div>
        </section >
     
    )
}

export default Aboutme
