import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import Contact from './Contact';


const Footer = () => {

    return (
        <footer>

            <div className="content">

                <Contact/>

                <div className="social">
                    <span className="logo">Contáctame</span>
                    <a href="https://www.instagram.com/diego.quindimil/"><i className="fab fa-instagram"></i>diego.quindimil</a>
                    <a href="https://www.linkedin.com/in/diego-quindimil-779b64a/"><i className="fab fa-linkedin-in"></i>diego-quindimil</a>
                    <a href="https://www.facebook.com/diego.quindimil"><i className="fab fa-facebook-f"></i>diego.quindimil</a>
                    <Link to="login"><i class="far fa-user-lock"></i>Interno</Link>
                </div>


                <TwitterTimelineEmbed 
                    className="twContainer"
                    sourceType="profile"
                    screenName="quindimildiego"
                    options={{height: 400, width: 300}}
                    theme="dark"
                    />

            </div>

            <span className="copyright">Design by <a href="https://it-techgroup.com/">IT TECHGROUP</a></span>
        </footer>
    )
}

export default Footer
