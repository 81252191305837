import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkJ } from 'react-scroll';

import Aos from "aos";
import "aos/dist/aos.css";

const Principal = () => {

    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    return (
        <section className="principal" id="principal">
            <img src="/diego.png" alt="Foto de fondo Diego Quindimil" />
            <div className="bgk" data-aos={window.innerWidth >= 768 ? "fade-left" : "fade-up"}>
                
                <div className="text" data-aos={window.innerWidth >= 768 ? "fade-up" : ""}>
                    <h1 data-aos={window.innerWidth > 768 ? "fade-up" : ""} data-aos-delay="300">Diego Quindimil</h1>
                    <h3 data-aos={window.innerWidth > 768 ? "fade-up" : ""} data-aos-delay="600">Psicólogo Clínico y Coach</h3>
                    <div className="social">
                        <a href="https://twitter.com/quindimildiego"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.instagram.com/diego.quindimil/"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/in/diego-quindimil-779b64a/"><i className="fab fa-linkedin-in"></i></a>
                        <a href="https://www.facebook.com/diego.quindimil"><i className="fab fa-facebook-f"></i></a>
                    </div>
                </div>
                <div className="nav-links" data-aos={window.innerWidth >= 768 ? "fade-up" : ""} data-aos-delay="1000">
                    <LinkJ className="link" to="aboutme" smooth={true}>Sobre mi</LinkJ>
                    <LinkJ className="link"  to="book" smooth={true}>Libro</LinkJ>
                    <LinkJ className="link" to="talent" smooth={true}>Consultora</LinkJ>
                    <LinkJ className="link"  to="media" smooth={true}>Mediateca</LinkJ>
                    <LinkJ className="link"  to="articles" smooth={true}>Escritos</LinkJ>
                    <LinkJ className="link"  to="eventos" smooth={true}>Eventos</LinkJ>
                </div>
            </div>
        </section>
    )
}

export default Principal
