import React, { useState, useEffect } from 'react';
import Principal from './sections/Principal';
import Talent from './sections/Talent';
import Articles from './sections/Articles';
import Media from './sections/Media';
import Book from './sections/Book';
import Aboutme from './sections/Aboutme';
import Webinar from './sections/Webinar';
import Footer from '../commons/Footer';
import Navbar from '../commons/Navbar';
import Newsletter from '../commons/Newsletter';


const Home = () => {
    
    const [ scrollValue, setScrollValue ] = useState(window.scrollY);
    const [ newsletter, setNewsletter ] = useState(true);


    useEffect(() => {
            window.addEventListener('scroll', ()=>{
                setScrollValue(window.scrollY);
            })


        }, [])


        
    return (
        <>
            {scrollValue>window.innerHeight &&  <Navbar/>}
            <Principal/>
            <Aboutme/>
            <Book/>
            <Talent/>
            <Media/>
            <Articles/>
            <Webinar/>
            {newsletter && <Newsletter setNewsletter={setNewsletter}/>}
            <Footer/>
        </>
    )
}

export default Home
