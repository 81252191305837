import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from '../../commons/Navbar';
import Footer from '../../commons/Footer';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const items = [
"Hacer Zoom en la Post Pandémica", 
"La tecnología y la invasión de la intimidad",
"La dinámica de la nueva oficina",
"La nueva normalidad, el amor, el sexo y los vinculos post covid",
"Psicovid: Los impactos psicológicos de la pandemia", 
"La reinserción de los resilientes", 
"El mundo del trabajo híbrido", 
"Luces y sombras del teletrabajo", 
"Del teletrabajo al tecno stress",
"Las organizaciones ante la realidad híbrida",
"Trauma post covid el impacto del regreso",
"La post pandemia y las nuevas normalidades"]

const Book = () => {

    useEffect(() => {
        Aos.init({duration:2000})
    }, [])


    return (
        
        <section className="book" id="book">
            <div className="img-container">
                <div className="title" data-aos="fade-down">
                    <h3>Libro</h3>
                    <span className="subs"></span>
                </div>
                <img src="/book.png" alt="Foto del libro" data-aos="fade-down"/>
                <div className="buttons-container" data-aos="fade-up">
                    <div className="button-field">
                        <a href="https://granicaeditor.com/libro.asp?isbn=9789878358826" target="_blank"><button className="btn btn-warning">Comprar digital <i class="fal fa-shopping-cart"></i></button></a>
                        <label>a través de Ediciones Granica</label>
                    </div>
                    <div className="button-field">
                        <a href="https://articulo.mercadolibre.com.ar/MLA-1105700180-libro-mundo-post-covid-_JM" target="_blank"><button className="btn btn-warning">Comprar físico <i class="fal fa-shopping-cart"></i></button></a>
                        <label>a través de Mercado Libre</label>
                    </div>
                </div>
            </div>
            <div className="text" data-aos={window.innerWidth>=768? "fade-left":"fade-up"}>
                <div className="epilogo">¿La pandemia ha llegado a su fin? No lo sabemos. El final está más cerca, sí. Y ahora empiezan a verse con claridad como ha quedado el mundo tras su paso. Desde su experiencia como psicólogo clínico, consultor de empresas y docente universitario, Diego Quindimil analiza el mundo del trabajo que deja la pandemia en su libro Mundo Post Covid (Ed. Granica). <br/>El autor no solo releva aquello que está sucediendo sino que, además, nos ofrece herramientas que enriquecen nuestra labor profesional, lo que nos permite reflexionar acerca del futuro del trabajo y de la vida misma postpandémica. <br/>El libro desmenuza el impacto en organizaciones, tanto privadas como públicas, las personas y sus vínculos, entre sí y con las organizaciones, los aspectos psicológicos a nivel individual y grupal, la salud mental, la determinación social, y los escenarios que vienen.<br/>En este nuevo contexto, el término “híbrido” cobra una importancia recurrente: educación, capacitación, reunión, vinculación, intercambio, donde lo presencial y lo virtual deben alternarse.</div>
                <p className="title">Algunos temas que aborda el libro</p>
                <Splide
                    options ={{
                        type: 'slide',
                        pagination:false,
                        arrows: false,
                        autoplay: true, 
                        interval: 2000
                    }}
                >
                    {items.map(item =>
                        <SplideSlide>
                            <p className="item">{item}</p>
                        </SplideSlide>
                    )}
                </Splide>
            </div>
        </section>

    )
}

export default Book
