import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';


const Navbar = ({logout}) => {


    return (
        <div className="nav">
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Diego Quindimil
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>logout()}>Salir</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default Navbar
