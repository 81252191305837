import React, { useState } from 'react';
import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

const Newsletter = ({setNewsletter}) => {

    const [ mail, setMail ] = useState('');

    const sendMail = async () =>{
        await axios.post(`${REACT_APP_BACKEND_URL}newsletter`, {email: mail})
    }

    return (
        <div className="newsletter-container">
            <div className="btn-close" onClick={()=>setNewsletter(false)}></div>
            <span className="title">¿Quieres estar al tanto de novedades?</span>
            <span className="body">Suscribete para que puedas enterarte de las últimas noticias</span>
            <div className="input-field">
                <input type="text" placeholder="Tu email" name="mail" id="inp" onChange={(e)=>setMail(e.target.value)}/>
                <button className="btn btn-warning" onClick={()=>sendMail()}>Listo</button>
            </div>
            <span className="tiny-letter">Nunca te enviaré spam y puedes darte de baja cuando quieras</span>
        </div>
    )
}

export default Newsletter
