import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";


const Talent = () => {

    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    return (
        <section className="talent" id="talent">
            <div className="title" data-aos="fade-down">
                <h3>Contenido Humano</h3>
                <span className="subs"></span>
            </div>
            <div className="content">
                <div className="img-container">
                    <img src="./talento-humano.png" alt="" />
                </div>
                <div className="text" data-aos="fade-up">
                    <h3>Contenido humano, consultora boutique,  surge de la experiencia de trabajo en el área del talento humano durante más de 20 años. Nos dedicamos al asesoramiento e implementación de soluciones para cada cliente de manera particular y personalizada. El conocimiento adquirido nos permite comprender que cada organización, equipo o área de trabajo tiene sus complejidades y su resolución a la medida. Cada ámbito de trabajo es único en su cultura, su agrupamiento y funcionamiento. Desde esa perspectiva abordamos cada realidad organizacional, comprendiendo que no pueden aplicarse fórmulas o recetes generales. La palabra boutique, de origen francés, se ha popularizado para hacer referencia a pequeños negocios o tiendas en los cuales, se venden productos exclusivos para determinadas necesidades particulares. A través de nuestra disposición y entrega alcanzamos resultados reales, integrados y duraderos. Nos importa fundamentalmente la sustentabilidad de cada acción. Aplicamos un pensamiento innovador a cada asesoría, con base en la confianza, brindando una solución creativa acorde a cada situación.</h3>
                </div>
                <div className="container-button">
                    <a href="http://www.contenidohumano.com.ar/" target="_blank"><button className="btn btn-warning">CONOCER MAS</button></a>
                </div>
            </div>
        </section>
    )   
}

export default Talent
