import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VideoField from './components/VideoField';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const { REACT_APP_BACKEND_URL } = process.env;

const Videos = () => {

    const [ videos, setVideos ] = useState([]);
    const [ modal, setModal ] = useState(false);
    const [ uploadVideo, setUploadVideo ] = useState('');

    useEffect(() => {
        getVideos();  
    }, [])
    
    const getVideos = async () =>{
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}video/`);
        setVideos(data);
    }

    const postVideo = async () =>{
        await axios.post(`${REACT_APP_BACKEND_URL}video/`,{link: uploadVideo});
        setUploadVideo('');
        getVideos();
    }

    const deleteVideo = async (id) =>{
        await axios.delete(`${REACT_APP_BACKEND_URL}video/${id}`);
        setUploadVideo('');
        getVideos();
    }

    const addVideo = (e) =>{
        e.preventDefault();

        let data = e.target.link.value;
        setUploadVideo(data.substring(17, 29));
    
    }

    return (
        <>
        <div className="menu">
            <div className="body">
            {
                videos.length > 0 ?
                videos.map(video => <VideoField video={video} deleteVideo={deleteVideo} getVideos={getVideos}/>)
                :
                <div className="empty-container">
                    <label className="empty">No hay videos aún, ¡registra uno!</label>
                </div>
            }
            </div>
            <div className="footer">
                <span className="btn btn-warning mt-4" onClick={()=>setModal(!modal)}>Registrar video</span>
            </div>
        </div>


        <Modal isOpen={modal}>
            <form onSubmit={addVideo}>
            <ModalHeader>
                Agrega un video
            </ModalHeader>
            <ModalBody>
                <div className="form-group mt-3">
                    <input type="text" class="form-control" placeholder="Youtube Link" name="link"/>
                    <div className="d-flex justify-content-center mt-2">
                        <button type="submit" class="btn btn-warning my-4">Agregar</button>
                    </div>
                </div>
                
                { uploadVideo && <LiteYouTubeEmbed 
                        id={uploadVideo}
                        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                    />
                }
            </ModalBody>    
            <ModalFooter>
                <div className="w-100 d-flex justify-content-center">
                    <button 
                        class="btn btn-secondary mx-3" 
                        onClick={()=>{
                            setModal(!modal); 
                            setUploadVideo('');
                        }}
                        >Cancelar</button>

                    {uploadVideo && <button 
                        class="btn btn-success mx-3" 
                        onClick={()=>{setModal(!modal); 
                            setUploadVideo(''); 
                            postVideo();
                    }}>Confirmar</button>}
                </div>
            </ModalFooter>
            </form>
        </Modal>
    </>
    )
}

export default Videos

