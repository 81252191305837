import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import axios from 'axios';
import PostField from './components/PostField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const { REACT_APP_BACKEND_URL } = process.env;

const Posts = () =>{
    
    const [ posts, setPosts ] = useState([]);
    const [ modalCreate, setModalCreate ] = useState(false);
   
    useEffect(()=>{
       
       getPosts();
    },[])

    const validations = yup.object({
        title: yup.string()
        .min(5, 'Debe tener como mínimo 5 caracteres')
        .max(120, 'Puedes ingresar hasta 120 caracteres')
        .required('Debes ingresar un título para la publicación'),
        body: yup.string()
        .min(30, 'La publicación es muy corta')
        .max(1500, 'Puedes ingresar hasta 1500 caracteres')
        .required('El cuerpo de la publicación es obligatorio'),
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue  } = useForm({
        resolver: yupResolver(validations),
    });


    const onSubmit = async (data) =>{
        postPost({
            title: data.title,
            body: data.body
        });
        setModalCreate(false);
        reset();
        getPosts();
    }
    

    const getPosts = async () =>{
        const {data} = await axios.get(`${REACT_APP_BACKEND_URL}post/`); 
        setPosts(data.sort((a,b)=>b.id-a.id));
    }

    const postPost = async (post) =>{
        try {
            await axios.post(`${REACT_APP_BACKEND_URL}post/`,post);
        } catch (error) {
            console.log(error);            
        }
        await getPosts();
    }

    const putPost = async (post) =>{
        try {
            await axios.put(`${REACT_APP_BACKEND_URL}post/${post.id}`,post);
        } catch (error) {
            console.log(error);            
        }
        await getPosts();
    }

    const deletePost = async (post) =>{
        try {
            await axios.delete(`${REACT_APP_BACKEND_URL}post/${post.id}`);
        } catch (error) {
            console.log(error);            
        }
        await getPosts();
    }
    

    return (
        <>
            <div className="menu">
                <div className="body">
                    {posts.length > 0 ?
                    posts.map(post =>
                        <PostField 
                            post={post} 
                            deletePost={deletePost} 
                            putPost={putPost}
                            setModalCreate={setModalCreate}
                        />)
                    :
                    <div className="empty-container">
                        <span>¡Sin publicaciones aún, crea una!</span>
                    </div>
                    }
                    
                </div>
                <div className="footer">
                    <button className="btn btn-warning" onClick={()=>setModalCreate(true)}>Nueva publicación</button>
                </div>
            </div>

            <Modal isOpen={modalCreate}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        Crear publicación
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="title">Titulo</label>
                            <input type="text" name="title" id="title" className="form-control"  {...register('title')}/>
                            {errors.title && <span>{errors.title?.message}</span>} 
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="body">Cuerpo</label>
                            <textarea type="text" name="body" id="body" className="form-control" rows="5" {...register('body')}/>
                            {errors.body && <span>{errors.body?.message}</span>} 
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <span onClick={()=>setModalCreate(false)} className="btn btn-secondary mx-3">Cancelar</span>
                            <button type="submit" className="btn btn-primary mx-3">Guardar</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )


}

export default Posts;